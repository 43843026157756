import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from '@/firebase/init';
import NotFound404 from "../components/404/NotFound404";
import Home from '../views/Home';
import Dashboard from '../views/dashboard/Dashboard';
import Onboarding from '../views/onboarding/Onboarding';
import ProjectSetUp from '../views/projectSetup/ProjectSetUp';
import Builder from '../views/builder/Builder';
import WebsiteSetUp from '../views/websiteSetup/WebsiteSetUp';
import Account from '../views/account/Account';
import FormFreeTraining from '../views/landing-pages/FormFreeTraining';
import FormEndOfTraining from '../views/landing-pages/FormEndOfTraining';
import Academy_v2 from "../views/academy-v2.0/Academy";
import Lesson_v2 from "../views/academy-v2.0/Lesson";
import Webinars from "../views/webinars/Webinars";
import Webinar from "../views/webinars/Webinar";
import ProjectManagement from "../views/project-management/ProjectManagement";
// import Tickets from "../views/support/Tickets";
// import Ticket from "../views/support/components/Ticket";
import Partnerships from '../views/partnerships/Partnerships'
import Ressources from '../views/ressources/Ressources';
import Quizzes from '../views/quizzes/Quizzes';
import Welcome from '../views/welcome/Welcome';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: "Home",
    component: Home
  },


  {
    path: '/dashboard/course/:course',
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      displayTitle: 'Tableau de bord'
    }

  },

  {
    path: '/welcome',
    name: "welcome",
    component: Welcome,
    meta: {
      requiresAuth: true,
      displayTitle: 'Welcome'
    }

  },

  {
    path: '/suivi-de-projet/course/:course',
    name: "project-management",
    component: ProjectManagement,
    meta: {
      requiresAuth: true,
      displayTitle: 'Suivi de mon projet'
    }

  },

  {
    path: '/academie/course/:course',
    name: "academie",
    component: Academy_v2,
    meta: {
      requiresAuth: true,
      displayTitle: "L'Académie"
    }

  },

  {
    path: '/academie/course/:course/:module/:chapter/:lesson',
    name: "lesson",
    component: Lesson_v2,
    meta: {
      requiresAuth: true,
      displayTitle: "L'Académie"
    }

  },

  {
    path: '/postuler',
    name: "onboarding",
    component: Onboarding
  },

  {
    path: '/bienvenue',
    name: "project-setup",
    component: ProjectSetUp,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/mockup-generator/course/:course',
    name: "builder",
    component: Builder,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/installer-mon-site',
    name: "installation",
    component: WebsiteSetUp,
    meta: {
      requiresAuth: true,
      displayTitle: 'Installer mon site'
    }
  },

  {
    path: '/webinars/course/:course',
    name: "webinars",
    component: Webinars,
    meta: {
      requiresAuth: true,
      displayTitle: 'Webinars'
    }
  },

  {
    path: '/webinars/course/:course/:id',
    name: "webinar",
    component: Webinar,
    meta: {
      requiresAuth: true,
      displayTitle: 'Webinar'
    }
  },

  // {
  //   path: '/mes-tickets/course/:course',
  //   name: "tickets",
  //   component: Tickets,
  //   meta: {
  //     requiresAuth: true,
  //     displayTitle: 'Tickets'
  //   }
  // },

  // {
  //   path: '/ticket/course/:course/:id',
  //   name: "ticket",
  //   component: Ticket,
  //   meta: {
  //     requiresAuth: true,
  //     displayTitle: 'Ticket'
  //   }
  // },

  {
    path: '/mon-compte/course/:course',
    name: "account",
    component: Account,
    meta: {
      requiresAuth: true,
      displayTitle: "Mon compte"
    }
  },

  {
    path: '/bons-plans/course/:course',
    name: "partnerships",
    component: Partnerships,
    meta: {
      requiresAuth: true,
      displayTitle: 'Bons Plans'
    }
  },

  {
    path: '/ressources/course/:course',
    name: "ressources",
    component: Ressources,
    meta: {
      requiresAuth: true,
      displayTitle: 'Ressources'
    }
  },

  {
    path: '/quizzes/course/:course',
    name: "quizzes",
    component: Quizzes,
    meta: {
      requiresAuth: true,
      displayTitle: 'Quizzes'
    }
  },

  {
    path: '/avis-formation-gratuite',
    name: "form-free-training",
    component: FormFreeTraining,

  },

  {
    path: '/avis-formation',
    name: "form-end-of-training",
    component: FormEndOfTraining,

  },

  {
    path: '*',
    component: NotFound404
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;
  if (requiresAuth && !currentUser) {
    next({ name: 'Home' });
  } else {
    next();
  }
});


export default router