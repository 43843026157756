<template>
  <v-container fluid class="pa-6">
    <v-row class="pl-6" style="display: block">
      <p class="headline font-weight-bold black--text mb-0">
        Quizz - Entraînez-vous !
        <span class="grey--text font-weight-regular"
          >({{ quizzes.length }})</span
        >
      </p>
      <v-subheader class="pl-0">
        Retrouvez ici des quizz et QCM pour tester vos connaissances sur les concepts théoriques de votre formation 🎓
      </v-subheader>
    </v-row>

    <v-row justify="start">
    <v-col
      cols="12" md="4"
      v-for="(quizz, index) in quizzes"
      :key="index"
      class="d-flex flex-column my-2"
    >
      <v-skeleton-loader
        type="card"
        :loading="loading"
        class="flex d-flex flex-column"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            class="pa-2 flex d-flex flex-column dashboard-card"
            flat
          >
            <v-img :src="quizz.img" max-height="200" class="rounded">
              <v-fade-transition>
                <v-overlay v-if="hover" absolute>
                  <v-btn
                    fab
                    color="blue accent-4"
                    :href="quizz.url"
                    target="_blank"
                    ><v-icon>mdi-open-in-new</v-icon></v-btn
                  >
                </v-overlay>
              </v-fade-transition>
            </v-img>

            <v-card-subtitle class="font-weight-medium black--text pl-1">
              {{ quizz.title }}
              <v-spacer></v-spacer>
              <v-chip small :color="chipColor">{{ quizz.cat }}</v-chip>
            </v-card-subtitle>

            <v-card-actions class="my-0 py-0">
              <v-spacer></v-spacer>
              <v-btn text color="black" small :href="quizz.url"
              target="_blank">Démarrer <v-icon small right>mdi-chevron-right</v-icon></v-btn>
            </v-card-actions>
            
          </v-card>
        </v-hover>
      </v-skeleton-loader>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Quizzes",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(["quizzes", "user_data","course"]),
    chipColor(){
      if(this.course.cat === 'marketing'){
        return "#F9DA74"
      }
      else if(this.course.cat === 'creation'){
        return "#D688E9"
      }
      else if(this.course.cat === 'web'){
        return "#3FCBED"
      }
      else {
        return ''
      }
    },
  },
  methods: {
    catIcon(cat) {
      if (cat === "Documents") {
        return "mdi-file-document";
      } else if (cat === "Fiches") {
        return "mdi-file-pdf-box";
      } else if (cat === "Modèles") {
        return "mdi-file-chart";
      } else if (cat === "Plugins") {
        return "mdi-power-plug";
      } else if (cat === "Thèmes") {
        return "mdi-brush";
      }
      else if (cat === "Exercices") {
        return "mdi-dumbbell";
      }
    },
    lazyLoad() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 600);
    },
  },
  async mounted() {
    await this.$store.dispatch("setQuizzes", this.$route.params.course);
    setTimeout(() => {
      this.loading = false;
    }, 1200);
  },
};
</script>

<style>
</style>